<template>
  <div id="app-footer">
    <div id="app-footer-inner">
      <ul v-if="dealerJSON.pages.length > 0" class="hori-menu url pl-3 pr-3">
        <li v-for="(page, idx) in bottomPages" :key="idx"><router-link :to="page.url_alias" target="_self">{{ page.menu_name }}</router-link></li>
      </ul>
      <ul v-if="dealerJSON.socials.length > 0" class="hori-menu social pl-2 pr-3 pb-2">
        <li v-for="(social, idx) in dealerJSON.socials" :key="idx">
          <v-btn :aria-label="social.type" :class="social.type" icon color="#ffffff" target="_blank" :href="social.url"><v-icon size="30">mdi-{{social.type}}</v-icon></v-btn>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'; // introducing mapState for store

export default {
  name: 'Footer',
  computed: {
    ...mapState({
      dealerJSON: state => state.dealerJSON, // get data from store
    }),
    bottomPages() {
      // filter pages array on menu_placement == 'bottom'
      var newArray = this.dealerJSON.pages.filter(item => {return item.menu_placement == 'bottom'});
      return newArray; // return the filtered Array for use in ul footer page url's
    }
  }
};
</script>

<style lang="scss">
  #app-footer {
    width: 100%;
    //margin: 20px 0 0;
    background-color: #c6beb7;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 76px;
    #app-footer-inner {
      max-width: 1200px;
      background-color: #c6beb7;
      width: 100%;
      margin:0 auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 0 10px;
      ul.hori-menu {
        padding: 20px 0 0;
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        li {
          margin: 0 16px 0 0;
          align-self: center;
          height: 36px;
        }
        li,a {
          color: white;
        }
        a {
          vertical-align: middle;
          text-decoration: none;
        }
        a:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .footer-space {
    padding-bottom: 76px;
  }
  li a.linkedin i {
    font-size: 36px !important;
  }
</style>