<template>
  <div id="errorPage" class="app-bar-padding-top footer-space">
    <!-- top bar -->
    <CustomAppBar v-if="dealerJSON" @MainMenuClick="$emit('toggleMainMenu')" v-on="$listeners" />
    <!-- top bar -->
    <div id="error-wrapper" class="page-size pa-6">
      <div id="errorTitle" class="mb-4" v-html="dealerConfigData.error_message.title"></div>
      <div id="errorMessage" v-html="dealerConfigData.error_message.message" class="pb-4"></div>
      <div class="back-button-wrapper">
        <v-btn to="/" class="font-weight-bold back-button">
          <v-icon small class="mr-2">mdi-arrow-left-circle</v-icon>
          Terug naar home
        </v-btn>
      </div>
    </div>
    <!-- Footer -->
    <Footer v-if="dealerJSON"></Footer>
  </div>
</template>

<script>
import CustomAppBar from '@/components/bars/CustomAppBar.vue';
import Footer from '@/components/bars/Footer.vue';
import { mapState } from 'vuex'; // introducing mapState for store

export default {
  name: 'error',
  components: {
    CustomAppBar,
    Footer
  },
  data() {
    return {
      pageTitle: "PageTitle"
    }
  },
  metaInfo() {
    return {
      title: this.dealerConfigData.error_message.pageTitle
    }
  },
  mounted() {
    if (String(window.location.href) === String(window.location.origin + '/404')) {
      // do nothing if url already matches the string we want to use to replace url address bar.
    }
    else {
      this.$router.replace({path: '/404'}); // always go to stap 1
    }
  },
  computed: mapState({
    dealerJSON: state => state.dealerJSON, // get data from store
    dealerConfigData: state => state.dealerConfigData // get data from store
  })
}
</script>

<style lang="scss">
#error-wrapper .back-button-wrapper {
  text-align: center;
}
#errorMessage {
  text-align: center;
}
</style>